import * as React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';

const StyledInnerContainer = styled('div')`
	max-width: 840px;
	margin: 0 auto;
	padding: 0 1em;
	flex-grow: 1;

	img {
		margin: 40px auto;
		display: block;
		border-radius: 50%;
		overflow: hidden;
	}

	.full-line {
		margin: 2em 0;

		&:before {
			content: '';
			width: 100%;
			position: absolute;
			left: 0;
			right: 0;
			border: 0;
			border-top: 3px solid #000;
		}
	}
`;

const StyledHeader = styled('h2')`
    text-align: center;
    font-weight: 800;
    font-family 'Work Sans', sans-serif;
`;

export default ({ data }) => {
	const {
		frontmatter: { title },
		html
	} = data.markdownRemark;
	return (
		<Layout>
			<Helmet>
				<title>{title} - do dizajnu</title>
				<html lang="pl" />
			</Helmet>
			<TopBar />
			<StyledInnerContainer>
				<article>
					<header>
						<StyledHeader>{title}</StyledHeader>
					</header>
					<div dangerouslySetInnerHTML={{ __html: html }} />
				</article>
			</StyledInnerContainer>
			<Footer />
		</Layout>
	);
};

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
			}
		}
	}
`;
